var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.groupTest")))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.options.search = _vm.searchForm}}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchForm ? 'mdi-close' : '',"prepend-icon":"mdi-magnify","label":_vm.$vuetify.lang.t('$vuetify.search'),"single-line":"","hide-details":""},on:{"click:append":_vm.clearSearch},model:{value:(_vm.searchForm),callback:function ($$v) {_vm.searchForm=$$v},expression:"searchForm"}})],1)],1)],1),_c('highcharts',{staticClass:"mb-4",attrs:{"options":_vm.chartOptions}}),_c('data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.groups,"options":_vm.options,"server-items-length":_vm.totalData,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.instituteName",fn:function(ref){
var instituteName = ref.item.instituteName;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"pointer",on:{"click":function($event){_vm.searchForm = instituteName;
              _vm.options.search = instituteName;
              _vm.options.page = 1;}}},'span',attrs,false),on),[_vm._v(_vm._s(instituteName))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.filterInstitute")))])])]}},{key:"top",fn:function(ref){
              var pagination = ref.pagination;
              var options = ref.options;
              var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[25, 50, 100, -1],"options":options,"pagination":pagination,"show-first-last-page":true,"show-current-page":true},on:{"update:options":updateOptions}})]}},{key:"item.action",fn:function(ref){
              var id = ref.item.id;
return [_c('div',{staticStyle:{"width":"100px"}},[_c('v-btn',{staticClass:"mb-1",attrs:{"to":{ name: 'Result.Group.Test', params: { group_id: id } },"color":"primary"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.testList")))])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }