<template>
  <v-container>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>{{ $vuetify.lang.t("$vuetify.groupTest") }}</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-form @submit.prevent="options.search = searchForm">
          <v-text-field
            v-model="searchForm"
            @click:append="clearSearch"
            :append-icon="searchForm ? 'mdi-close' : ''"
            prepend-icon="mdi-magnify"
            :label="$vuetify.lang.t('$vuetify.search')"
            single-line
            hide-details
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>

    <highcharts :options="chartOptions" class="mb-4"></highcharts>

    <data-table
      :headers="headers"
      :items="groups"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.instituteName`]="{ item: { instituteName } }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              @click="
                searchForm = instituteName;
                options.search = instituteName;
                options.page = 1;
              "
              class="pointer"
              >{{ instituteName }}</span
            >
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.filterInstitute") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          first-icon="mdi-chevron-double-left"
          last-icon="mdi-chevron-double-right"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          :items-per-page-options="[25, 50, 100, -1]"
          :options="options"
          :pagination="pagination"
          :show-first-last-page="true"
          :show-current-page="true"
        />
      </template>
      <template v-slot:[`item.action`]="{ item: { id } }">
        <div style="width: 100px">
          <v-btn
            :to="{ name: 'Result.Group.Test', params: { group_id: id } }"
            color="primary"
            class="mb-1"
            >{{ $vuetify.lang.t("$vuetify.testList") }}</v-btn
          >
        </div>
      </template>
    </data-table>
  </v-container>
</template>

<script>
import { Chart } from "highcharts-vue";
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Group from "@/services/group";
import Test from "@/services/test";

export default {
  name: "Result.Group.Index",
  data() {
    return {
      totalData: 0,
      groups: [],
      loading: true,
      options: {
        search: undefined
      },
      headers: [
        { text: "Group Name", value: "name" },
        { text: "Institute", value: "instituteName" },
        { text: "Average Score", value: "averageScore" },
        { text: "Action", value: "action" }
      ],
      chartData: [],
      searchForm: null
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 1500,
            scrollPositionX: 1
          }
        },
        title: {
          text: `Group Test Score`
        },
        xAxis: {
          type: "category"
        },
        yAxis: {
          title: {
            text: "Score"
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: "{point.y}"
            }
          }
        },
        series: [
          {
            name: "Group Test Score",
            colorByPoint: true,
            data: this.chartData
          }
        ]
      };
    }
  },
  components: {
    highcharts: Chart,
    DataTable
  },
  watch: {
    options: {
      handler() {
        this.getGroups();
      },
      deep: true
    }
  },
  created() {
    this.setNavbarTitle("Group Result");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getGroups() {
      this.loading = true;
      this.groups = [];
      this.results = [];
      const [groups, results] = await Promise.all([
        Group.index({ ...this.options, sortBy: "name", score: true }),
        Test.groupTestResult()
      ]);
      this.groups = groups.data.data.groups;
      this.results = results.data.data;
      this.totalData = groups.data.data.total_data;
      this.chartData = results.data.data.map(({ name, averageScore: y }) => ({
        name,
        y
      }));
      this.loading = false;
    },
    clearSearch() {
      this.options.search = undefined;
      this.searchForm = null;
    }
  }
};
</script>

<style scoped>
.pointer:hover {
  cursor: pointer;
}
</style>
